import * as React from "react";

import classNames from "classnames";

import Header from "../components/header";
import Footer from "../components/footer";
import Gallery from "../components/gallery";

import pic1 from "../assets/images/specialty/1.jpg";
import pic2 from "../assets/images/specialty/2.jpg";
import pic3 from "../assets/images/specialty/3.jpg";
import pic4 from "../assets/images/specialty/4.jpg";

import GalleryHeader from "../components/galleryheader";

const galleryColumns = [
  [pic3, pic1],
  [pic2],
  [pic4]
];

const SpecialtyPage = () => (
  <React.Fragment>
    <Header />
      <GalleryHeader title="Specialty" />
      <Gallery columns={galleryColumns} />
    <Footer />
  </React.Fragment>
);

export default SpecialtyPage;
